'use client';

import { config } from '../config';
import { InstantSearch } from 'react-instantsearch';
import { SearchResults } from './search-results';
import { SearchForm } from './search-form';
import { FilterForm } from './filter-form';
import { MobileFilterModal } from './mobile-filter-modal';

export const Search1a = ({
    search_form,
    filter_labels,
    talent_community,
    showing_results,
    no_results,
    clear_filters,
}) => {
    return (
        <InstantSearch {...config}>
            <div className="jobsearch__top">
                <SearchForm search_form={search_form} />
                <MobileFilterModal filter_labels={filter_labels} />
                {talent_community && <ContentZone blocks={talent_community} />}
            </div>
            <div className="grid grid-md-12">
                <div className="col-md-8">
                    <SearchResults
                        showing_results={showing_results}
                        no_results={no_results}
                        clear_filters={clear_filters}
                    />
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <FilterForm {...filter_labels} />
                </div>
            </div>
        </InstantSearch>
    );
};
