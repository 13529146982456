import { Icon } from '@/components/icon';
import Link from 'next/link';
import { slugify } from '@/lib/utils/slugify';
/*
id: string;
JobPostingTitle: string;
JobRequisition: string;
SecondaryJobPostingLocation: string;
Company: string;
PrimaryJobPostingLocation: string;
Location: string;
Country: string;
TimeType: string;
EmployeeType: string;
BusinessUnit: string;
ApplyUrl: string;
Category: string;
objectID: string;
Remote: boolean;
*/

export const SearchResult = ({ result }) => {
    const id = result.id.replace('Req-', '');
    const title = slugify(result.JobPostingTitle);
    return (
        <Link href={`/job/${id}/${title}`} className="jobsearch__result">
            <h4 className="jobsearch__result-title">
                {result.JobPostingTitle}{' '}
                {result.Remote && (
                    <span className="jobsearch__remote-badge">Remote</span>
                )}
            </h4>

            <div className="jobsearch__result-details">
                <div className="jobsearch__result-detail">
                    <Icon icon={'fa-regular fa-location-dot'} />
                    {result.SecondaryJobPostingLocation
                        ? 'Multiple Locations'
                        : result?.PrimaryJobPostingLocation || result?.Location}
                </div>
                <div className="jobsearch__result-detail">
                    <Icon icon={'fa-regular fa-clock'} />
                    {result.TimeType}
                </div>
                <div className="jobsearch__result-detail">
                    <Icon icon={'fa-regular fa-hashtag'} />
                    {result.id}
                </div>
            </div>
        </Link>
    );
};
