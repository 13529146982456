import { usePagination } from 'react-instantsearch';
import classNames from 'classnames';
import { Icon } from '@/components/icon';
import { forwardRef } from 'react';

export const Pagination = forwardRef((props, ref) => {
    const { currentRefinement, pages, nbPages, refine } = usePagination(props);
    const firstPageIndex = 0;
    const previousPageIndex = currentRefinement - 1;
    const nextPageIndex = currentRefinement + 1;
    const lastPageIndex = nbPages - 1;

    function refocus() {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    function handlePage(event, page) {
        event.preventDefault();
        refine(page);
        refocus();
    }

    function handlePrevious() {
        if (currentRefinement === firstPageIndex) return;
        refine(previousPageIndex);
        refocus();
    }
    function handleNext() {
        if (currentRefinement === lastPageIndex) return;
        refine(nextPageIndex);
        refocus();
    }
    if (nbPages <= 1) return null;
    return (
        <nav className="pagination">
            <ul className="pagination__list">
                <li className="pagination__item">
                    <button
                        aria-label={'previous'}
                        onClick={handlePrevious}
                        className={classNames('pagination__link', {
                            'pagination__link--disabled':
                                currentRefinement === firstPageIndex,
                        })}
                    >
                        <Icon
                            icon={'fa-solid fa-chevron-left'}
                            aria-label="Previous Page"
                        />
                    </button>
                </li>
                {pages.map((page) => (
                    <li key={page} className="pagination__item">
                        <a
                            className={classNames('pagination__link', {
                                'pagination__link--active':
                                    currentRefinement === page,
                            })}
                            href="#"
                            onClick={(event) => handlePage(event, page)}
                        >
                            {page + 1}
                        </a>
                    </li>
                ))}
                <li className="pagination__item">
                    <button
                        aria-label={'next'}
                        onClick={handleNext}
                        className={classNames('pagination__link', {
                            'pagination__link--disabled':
                                currentRefinement === lastPageIndex,
                        })}
                    >
                        <Icon
                            icon={'fa-solid fa-chevron-right'}
                            aria-label="Next Page"
                        />
                    </button>
                </li>
            </ul>
        </nav>
    );
});
