'use client';

import { InstantSearch } from 'react-instantsearch';
import { FeaturedResults } from '../elements/featured-results';
import { algoliasearch } from 'algoliasearch';

const searchClient = algoliasearch(
    process.env.ALGOLIA_APPLICATION_ID,
    process.env.ALGOLIA_SEARCH_API_KEY
);

export const Search2a = ({ filters, talent_community }) => {
    return (
        <InstantSearch
            searchClient={searchClient}
            indexName={process.env.ALGOLIA_JOBSEARCH_INDEX}
            future={{
                preserveSharedStateOnUnmount: true,
                cleanUrlOnDispose: false,
            }}
        >
            <FeaturedResults
                filters={filters}
                talent_community={talent_community}
            />
        </InstantSearch>
    );
};
