'use client';

import { Pagination } from './pagination';
import { SearchResult } from './search-result';
import {
    useConfigure,
    useHits,
    usePagination,
    useSearchBox,
} from 'react-instantsearch';
import { useRef } from 'react';
import parse from 'html-react-parser';

/*
    "hitsPerPage": 10,
    "nbHits": 253,
    "areHitsSorted": false,
    "nbPages": 26,
    "page": 0,
    "processingTimeMS": 1,
    "query": ""
*/

export const SearchResults = ({
    showing_results,
    no_results,
    clear_filters,
}) => {
    useConfigure({ hitsPerPage: 10 });
    const results = useHits();
    const { currentRefinement, nbHits } = usePagination();
    const { clear } = useSearchBox();

    // Calculate the range of results to display
    const startResult = currentRefinement * 10 + 1;
    const endResult = Math.min((currentRefinement + 1) * 10, nbHits);

    const ref = useRef(null);

    function handleClear(e) {
        e.preventDefault();
        clear();
        const url = new URL(window.location);
        url.searchParams.delete('JobPostingTitle');
        url.searchParams.delete('Location');
        window.history.pushState({}, '', url);
        window.dispatchEvent(new CustomEvent('clearSearchForm'));
    }

    return (
        <div className="jobsearch__results-container" ref={ref}>
            {!!results.items.length && (
                <>
                    {startResult && (
                        <h3 className="mb-8">
                            {showing_results
                                .replace('${1}', `${startResult}-${endResult}`)
                                .replace('${2}', nbHits)}
                        </h3>
                    )}
                    <div className="jobsearch__results">
                        {results?.items?.map((result) => (
                            <SearchResult key={result.id} result={result} />
                        ))}
                        <Pagination ref={ref} />
                    </div>
                </>
            )}
            {!results.items.length && (
                <>
                    {no_results && parse(no_results)}
                    <a href="#" onClick={handleClear}>
                        {clear_filters}
                    </a>
                </>
            )}
        </div>
    );
};
