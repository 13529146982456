'use client';

import { useConfigure, useSearchBox } from 'react-instantsearch';
import { Form } from '@/blocks/form/elements/form';
import { useEffect, useCallback } from 'react';

export const SearchForm = ({ search_form }) => {
    const { refine } = useSearchBox();
    const { refine: configure } = useConfigure({});

    const updateQueryParams = (newParams) => {
        const url = new URL(window.location);
        Object.keys(newParams).forEach((key) => {
            url.searchParams.set(key, newParams[key]);
        });
        window.history.pushState({}, '', url);
    };

    function handleSearch(data) {
        const { JobPostingTitle, Location } = data;

        const restrictions = [];
        let query = '';
        let queryParams = {};

        if (JobPostingTitle) {
            restrictions.push('JobPostingTitle');
            query += JobPostingTitle;
            queryParams.JobPostingTitle = JobPostingTitle;
        }
        if (Location) {
            restrictions.push('Location');
            query += ` ${Location}`;
            queryParams.Location = Location;
        }

        updateQueryParams({ ...queryParams });

        configure({ restrictSearchableAttributes: restrictions });
        refine(query.trim());
    }

    const memoizedConfigure = useCallback(configure, []);
    const memoizedRefine = useCallback(refine, []);

    useEffect(() => {
        // Used for when we are sharing the url and need page to update with query params. Using window object instead of useRouter because algolia instant search has issues with useRouter.
        const handleClearForm = () => {
            document.querySelector(".jobsearch__search-form form").reset();
            const event = new CustomEvent("clearSearchFilters");
            window.dispatchEvent(event);
        };
        window.addEventListener('clearSearchForm', handleClearForm);

        const queryParams = new URLSearchParams(window.location.search);
        const JobPostingTitle = queryParams.get('JobPostingTitle');
        const Location = queryParams.get('Location');

        if (queryParams.toString()) {
            let query = '';
            const restrictions = [];

            if (JobPostingTitle) {
                restrictions.push('JobPostingTitle');
                query += JobPostingTitle;
            }
            if (Location) {
                restrictions.push('Location');
                query += ` ${Location}`;
            }

            memoizedConfigure({ restrictSearchableAttributes: restrictions });
            memoizedRefine(query.trim());
        }

        return () => {
            window.removeEventListener('clearSearchForm', handleClearForm);
        };

    }, [memoizedConfigure, memoizedRefine]);

    return (
        <div className="jobsearch__search-form">
            <Form submit={(_, data) => handleSearch(data)} form={search_form} />
        </div>
    );
};
