'use client';

import { useState, useEffect, useId } from 'react';
import {
    useRefinementList,
    useClearRefinements,
    useInstantSearch,
} from 'react-instantsearch';
import ReactSelect from 'react-select';
import classNames from 'classnames';

export const FilterForm = ({
    country,
    country_placeholder,
    location,
    location_placeholder,
    job_category,
    job_category_placeholder,
    job_type,
    job_type_placeholder,
    clear_filters,
    open_to_remote,
    apply,
    setIsOpen,
}) => {
    const { refine: clearRefinements } = useClearRefinements();
    const { indexUiState } = useInstantSearch();

    function handleClear() {
        clearRefinements();
    }

    useEffect(() => {
        window.addEventListener("clearSearchFilters", handleClear);
        return () => {
            window.removeEventListener("clearSearchFilters", handleClear);
        };
    }, [clearRefinements]);

    return (
        <>
            <div className="jobsearch__filter-form">
                <JobFilterSelect
                    attribute={'Country'}
                    label={country}
                    placeholder={country_placeholder}
                />
                <JobFilterCheckbox
                    attribute={'Remote'}
                    label={open_to_remote}
                />
                <hr />
                <JobFilterSelect
                    attribute={'PrimaryJobPostingLocation'}
                    label={location}
                    placeholder={location_placeholder}
                />
                <hr />
                <JobFilterSelect
                    attribute={'Category'}
                    label={job_category}
                    placeholder={job_category_placeholder}
                />
                <hr />
                <JobFilterSelect
                    attribute={'EmployeeType'}
                    label={job_type}
                    placeholder={job_type_placeholder}
                />
                <hr className="d-block d-md-none" />
                <button
                    className={classNames('d-none d-md-block btn btn-block', {
                        'btn-primary': indexUiState?.refinementList,
                        'btn-muted': !indexUiState?.refinementList,
                    })}
                    onClick={handleClear}
                >
                    {clear_filters}
                </button>
            </div>
            <div className="jobsearch__modal-btns">
                <button className="btn btn-ghost" onClick={handleClear}>
                    {clear_filters}
                </button>
                <button
                    className="btn btn-primary"
                    onClick={() => setIsOpen(false)}
                >
                    {apply}
                </button>
            </div>
        </>
    );
};

function JobFilterSelect({ attribute, label, placeholder }) {
    const { items, refine } = useRefinementList({
        attribute: attribute,
        sortBy: ['name:asc'],
        limit: 100,
    });
    const { refine: clear } = useClearRefinements({
        includedAttributes: [attribute],
    });

    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        // Update selectedOptions when items change
        const selectedItems = items
            .filter((item) => item.isRefined)
            .map((item) => ({
                value: item.value,
                label: item.label,
            }));
        setSelectedOptions(selectedItems);
    }, [items]);

    function handleChange(selectedOptions, context) {
        setSelectedOptions(selectedOptions || []);
        if (context.action === 'pop-value') {
            return refine(context.removedValue.value);
        }
        if (context.action === 'remove-value') {
            return refine(context.removedValue.value);
        }
        if (context.action === 'select-option') {
            return refine(context.option.value);
        }
        if (context.action === 'clear') {
            return clear();
        }
    }

    const formattedItems = items.map((item) => ({
        value: item.value,
        label: item.label,
    }));

    return (
        <div className="form form--lg">
            <h5 className="jobsearch__filter-title">{label}</h5>
            <ReactSelect
                options={formattedItems}
                onChange={handleChange}
                value={selectedOptions}
                className="select"
                classNamePrefix="select"
                isMulti
                placeholder={placeholder}
                instanceId={useId()}
            />
        </div>
    );
}

function JobFilterCheckbox({ attribute, label }) {
    const { items, refine } = useRefinementList({
        attribute: attribute,
        sortBy: ['name:asc'],
        limit: 100,
    });
    function handleChange(option) {
        refine(option.value);
    }
    if (!items.length) return null;
    return (
        <div>
            {items.map((option) => (
                <label key={option.value} className="form__item mb-0">
                    <input
                        className="form__selector"
                        type={'checkbox'}
                        checked={option.isRefined}
                        onChange={() => handleChange(option)}
                        value={option.value}
                    />
                    <div className="form__check"></div>
                    {label}
                </label>
            ))}
        </div>
    );
}
