'use client';

import { useModal } from '@/lib/hooks/use-modal';
import { FilterForm } from './filter-form';
import { useWindowDimensions } from '@/lib/hooks/use-window-dimensions';
import { useEffect } from 'react';
import { breakpoints } from '@/assets/data/breakpoints';
import { Icon } from '@/components/icon';
import dynamic from 'next/dynamic';
const FocusTrap = dynamic(() => import('focus-trap-react'));
import { useRef, useCallback } from 'react';
import reactDom from 'react-dom';
import classNames from 'classnames';

export const MobileFilterModal = ({ filter_labels }) => {
    const { isOpen, setIsOpen } = useModal();
    const { width } = useWindowDimensions();
    useEffect(() => {
        if (width > breakpoints.tablet) {
            setIsOpen(false);
        }
    }, [width]);
    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className={'jobsearch__mobile-filter-btn btn btn-muted'}
            >
                <Icon className="mr-2" icon={'fa-regular fa-bars-filter'} />
                Filters
            </button>
            <Modal
                className="jobsearch__filter-modal"
                popup_style="basic"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            >
                <div className="jobsearch__filter-modal-title">
                    Filters{' '}
                    <button
                        onClick={() => setIsOpen(false)}
                        className="jobsearch__filter-modal-x-btn"
                    >
                        <Icon
                            className={'jobsearch__filter-modal-x'}
                            icon={'fa-regular fa-xmark'}
                        />
                    </button>
                </div>
                <FilterForm setIsOpen={setIsOpen} {...filter_labels} />
            </Modal>
        </>
    );
};

const Modal = ({
    isOpen,
    setIsOpen,
    children,
    modalStyle,
    decline_redirect,
    popup_style = 'warning',
    className,
}) => {
    const modalRef = useRef();
    const handleClose = (e) => {
        if (modalRef.current === e.target && !decline_redirect) {
            setIsOpen(false);
        }
        if (modalRef.current === e.target && decline_redirect) {
            setIsOpen(false);
        }
    };
    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && isOpen) {
                setIsOpen(false);
            }
            if (e.key === 'Escape' && isOpen && decline_redirect) {
                setIsOpen(false);
                router.push(decline_redirect);
            }
        },
        [setIsOpen, isOpen]
    );
    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);
    return (
        <>
            {isOpen &&
                reactDom.createPortal(
                    <FocusTrap>
                        <div
                            role="dialog"
                            className={classNames('modal', {
                                [`${className}`]: className,
                            })}
                            aria-modal="true"
                            tabIndex="-1"
                            ref={modalRef}
                            onClick={handleClose}
                        >
                            <div
                                className={classNames('modal__content', {
                                    'modal__content--disable-x':
                                        popup_style === 'basic',
                                    'modal__content--multi':
                                        popup_style === 'multi',
                                })}
                            >
                                <button
                                    className={classNames('modal__close', {
                                        'modal__close--disable-x':
                                            popup_style === 'basic',
                                    })}
                                    onClick={() => setIsOpen(false)}
                                >
                                    <Icon icon={'fa-light fa-xmark'} />
                                </button>

                                <div
                                    className={classNames('modal__body', {
                                        'modal__body--video':
                                            modalStyle === 'video',
                                    })}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </FocusTrap>,
                    document.getElementById('portal')
                )}
        </>
    );
};
