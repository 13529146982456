import { SearchResult } from './search-result';
import { useConfigure, useHits } from 'react-instantsearch';
import { ContentZone } from '@/components/content-zone';

export const FeaturedResults = ({ filters, talent_community }) => {
    useConfigure({
        hitsPerPage: 5,
        filters: filters,
    });
    const results = useHits();

    return (
        <div className="jobsearch__results-container">
            {!!results.items.length && (
                <>
                    <div className="jobsearch__results">
                        {results?.items?.map((result) => (
                            <SearchResult key={result.id} result={result} />
                        ))}
                    </div>
                </>
            )}
            {!results.items.length && (
                <>
                    <div className="jobsearch__error-sections">
                        {talent_community && (
                            <ContentZone blocks={talent_community} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
