import { liteClient as algoliasearch } from "algoliasearch/lite";

const indexName = process.env.ALGOLIA_JOBSEARCH_INDEX;

export const config = {
    searchClient: algoliasearch(
        process.env.ALGOLIA_APPLICATION_ID,
        process.env.ALGOLIA_SEARCH_API_KEY
    ),
    insights: false,
    indexName,
    routing: {
        stateMapping: {
            stateToRoute(uiState) {
                const state = uiState[indexName];
                let existingParameters = null;
                if (typeof window !== 'undefined') {
                    existingParameters = new URLSearchParams(
                        window.location.search
                    );
                }

                let queryParams = {};
                if (existingParameters?.has('JobPostingTitle')) {
                    queryParams.JobPostingTitle =
                        existingParameters.get('JobPostingTitle');
                }
                if (existingParameters?.has('Location')) {
                    queryParams.Location = existingParameters.get('Location');
                }
                return {
                    query: state.query,
                    remote: state?.refinementList?.Remote && true,
                    location:
                        state.refinementList?.PrimaryJobPostingLocation &&
                        state.refinementList?.PrimaryJobPostingLocation.join(
                            ','
                        ),
                    country:
                        state.refinementList?.Country &&
                        state.refinementList?.Country.join(','),
                    'job-type':
                        state.refinementList?.EmployeeType &&
                        state.refinementList?.EmployeeType.join(','),
                    'job-category':
                        state.refinementList?.Category &&
                        state.refinementList?.Category.join(','),
                    ...queryParams,
                };
            },
            routeToState(routeState) {
                return {
                    [indexName]: {
                        ...routeState,
                        refinementList: {
                            Remote: routeState.remote && ['true'],
                            PrimaryJobPostingLocation:
                                routeState.location &&
                                routeState.location?.split(','),
                            Country:
                                routeState.country &&
                                routeState.country?.split(','),
                            EmployeeType:
                                routeState['job-type'] &&
                                routeState['job-type']?.split(','),
                            Category:
                                routeState['job-category'] &&
                                routeState['job-category']?.split(','),
                        },
                    },
                };
            },
        },
    },
    search(requests) {
        const newRequests = requests.map((request) => {
            if (!request.params.query || request.params.query.length === 0) {
                request.params.analytics = false;
            }
            return request;
        });

        return algoliaClient.search(newRequests);
    },
    future: {
        preserveSharedStateOnUnmount: true,
        cleanUrlOnDispose: false,
    },
};
